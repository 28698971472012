var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.whats,"items-per-page":_vm.getSettings.itemsperpage,"server-items-length":_vm.getSettings.serverItemsLength,"footer-props":_vm.getSettings.footerProps,"dense":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.getSettings, "itemsperpage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.getSettings, "itemsperpage", $event)},"update:page":_vm.handlePage},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-toolbar',{staticClass:"mr-0 pr-0",attrs:{"flat":""}},[_c('v-spacer'),_c('div',[_c('v-data-footer',_vm._b({attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","items-per-page":_vm.getSettings.itemsperpage,"server-items-length":_vm.getSettings.serverItemsLength},on:{"update:options":updateOptions,"update:itemsPerPage":function($event){return _vm.$set(_vm.getSettings, "itemsperpage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.getSettings, "itemsperpage", $event)}}},'v-data-footer',_vm.getSettings.footerProps,false))],1)],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("OD \"For What\" list")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{ref:"fiscalYear",staticClass:"odwhats fiscalYear",attrs:{"id":"odWhatFiscalYearText","type":"number","label":"Fiscal Year"},on:{"input":_vm.criteriaChanged},model:{value:(_vm.criteria.fiscalYear),callback:function ($$v) {_vm.$set(_vm.criteria, "fiscalYear", _vm._n($$v))},expression:"criteria.fiscalYear"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{staticClass:"odwhats copytoFY",attrs:{"id":"odWhatsCopyToFyText","type":"number","label":"Merge to FY"},on:{"input":_vm.criteriaChanged},model:{value:(_vm.copyToFY),callback:function ($$v) {_vm.copyToFY=_vm._n($$v)},expression:"copyToFY"}}),_c('v-btn',{staticClass:"mx-2",attrs:{"id":"odWhatsListMergeBtn","text":"","color":"primary"},on:{"click":_vm.copyAll}},[_vm._v(" Merge ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mx-2",attrs:{"id":"odWhatsListDeleteAllFyBtn","text":"","color":"primary"},on:{"click":_vm.clearAll}},[_vm._v(" Delete all for FY ")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"70vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canAdd)?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","fab":"","icon":""},on:{"click":_vm.newItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1):_vm._e()]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save($event)}}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.dialog)?_c('v-text-field',{staticClass:"od-whatlist-description-text",attrs:{"id":"odWhatListDescriptionText","label":"Description","autofocus":""},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"od-whatlist-fy-text",attrs:{"id":"odWhatListFyText","label":"Fiscal Year","readonly":""},model:{value:(_vm.editedItem.fiscalYear),callback:function ($$v) {_vm.$set(_vm.editedItem, "fiscalYear", $$v)},expression:"editedItem.fiscalYear"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"od-whatlist-close-btn",attrs:{"id":"odWhatListCloseBtn","text":"","color":"blue darken-1"},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"od-whatlist-save-btn",attrs:{"id":"odWhatListSaveBtn","text":"","color":"blue darken-1"},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canDelete)?_c('v-icon',{staticClass:"mr-2 od-browse-whatlist-delete-icon",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }