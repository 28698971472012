<template>
  <div>
    <WhatList :canAdd="true" :canEdit="true" :canDelete="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import WhatList from '@/components/OD/WhatList.vue';

export default {
  name: 'OfficialDepositoryWhats',
  components: {
    WhatList,
  },
};
</script>
