<template>
    <v-container>
        <v-data-table
          :headers="headers"
          :items="whats"
          :items-per-page.sync="getSettings.itemsperpage"
          :server-items-length="getSettings.serverItemsLength"
          :footer-props="getSettings.footerProps"
          @update:page="handlePage"
          dense
          class="elevation-1"
        >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-toolbar flat class="mr-0 pr-0">
            <v-spacer />
            <div>
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                v-bind="getSettings.footerProps"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page.sync="getSettings.itemsperpage"
                :server-items-length="getSettings.serverItemsLength"
              />
            </div>
          </v-toolbar>
          <v-toolbar
            flat
          >
            <v-toolbar-title>OD "For What" list</v-toolbar-title>
            <v-divider
              class="mx-4" inset vertical
            ></v-divider>
            <v-text-field id="odWhatFiscalYearText"
              class="odwhats fiscalYear"
              v-model.number="criteria.fiscalYear"
              type="number"
              ref="fiscalYear"
              label="Fiscal Year"
              @input="criteriaChanged"
              ></v-text-field>
            <v-divider
              class="mx-4" inset vertical
            ></v-divider>
            <v-text-field id="odWhatsCopyToFyText"
              class="odwhats copytoFY"
              v-model.number="copyToFY"
              type="number"
              label="Merge to FY"
              @input="criteriaChanged"
              ></v-text-field>
            <v-btn id="odWhatsListMergeBtn"
              class="mx-2"
              @click="copyAll"
              text color="primary">
              Merge
            </v-btn>
            <v-divider
              class="mx-4" inset vertical
            ></v-divider>
            <v-btn id="odWhatsListDeleteAllFyBtn"
              class="mx-2"
              @click="clearAll"
              text color="primary">
              Delete all for FY
            </v-btn>
            <v-spacer></v-spacer>
            <!-- New/Edit item dialog -->
            <v-dialog
              v-model="dialog" max-width="70vw"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="canAdd"
                  class="mx-2" color="primary"
                  fab icon
                  v-bind="attrs" v-on="on"
                  @click="newItem"
                >
                  <v-icon>
                    {{icons.mdiPlus}}
                  </v-icon>
                </v-btn>
              </template>
              <v-card @keydown.enter="save">
                <v-card-title>
                  <span class="headline">{{ dialogTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12" sm="6" md="4"
                      >
                        <v-text-field id="odWhatListDescriptionText"
                          class="od-whatlist-description-text"
                          v-model="editedItem.description"
                          label="Description"
                          v-if="dialog" autofocus
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12" sm="6" md="4"
                      >
                        <v-text-field id="odWhatListFyText"
                          class="od-whatlist-fy-text"
                          v-model="editedItem.fiscalYear"
                          label="Fiscal Year"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="odWhatListCloseBtn"
                    class="od-whatlist-close-btn"
                    text color="blue darken-1"
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn id="odWhatListSaveBtn"
                    class="od-whatlist-save-btn"
                    text color="blue darken-1"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.description`]="{ item, value }">
          <td class="text-start clickable" @click="editItem(item)">{{value}}</td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="canDelete"
            small class="mr-2 od-browse-whatlist-delete-icon"
            @click="deleteItem(item)"
          >
            {{icons.mdiDelete}}
          </v-icon>
        </template>

        </v-data-table>
    </v-container>
</template>

<script>
import {
  mdiPlus,
  mdiDelete,
} from '@mdi/js';
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  toFiscalYear,
} from '../../util/shared/tmc-global';
import {
  isInsert,
} from '../../util/shared/vue-global';

const fields = {
  _id: '',
  description: '',
  fiscalYear: toFiscalYear(new Date()),
};

export default {
  name: 'WhatList',

  props: {
    canAdd: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
    },
    copyToFY: undefined,
    criteria: {
      fiscalYear: undefined,
      wildcard: undefined,
      skip: undefined,
      limit: undefined,
    },
    headers: [
      { text: 'Description', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    dialog: false,
    editedIndex: -1,
    editedItem: { ...fields },
  }),
  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
    ...mapState({
      whats: (state) => state.OD.whats,
    }),
    ...mapGetters('user', [
      'getSettings',
    ]),
  },
  created() {
    document.onkeydown = this.shortcutHandler;
    this.criteria.fiscalYear = toFiscalYear(new Date());
    this.copyToFY = toFiscalYear(new Date());
    this.setCriteriaByPage(1);
    this.loadWhats(this.criteria);
  },
  methods: {
    ...mapActions('OD', [
      'loadWhats',
    ]),
    copyAll() {
      if (!window.confirm(`This will create any entries from FY ${this.criteria.fiscalYear} in FY ${this.copyToFY}. Continue?`)) {
        return;
      }
      const copyPromises = this.whats
        .map((w) => {
          const forNewW = { ...w };
          delete forNewW._id;
          forNewW.fiscalYear = this.copyToFY;
          return forNewW;
        })
        .map((w) => {
          const copyPromise = this.$store.dispatch('OD/upsWhat', w);
          return copyPromise;
        });
      Promise.all(copyPromises);
      this.loadWhats(this.criteria);
    },
    clearAll() {
      if (!window.confirm(`This will DELETE all entries from FY ${this.criteria.fiscalYear}. Continue?`)) {
        return;
      }
      const deletePromises = this.whats.map((w) => {
        const delPromise = this.$store.dispatch('OD/deleteWhat', w);
        return delPromise;
      });
      Promise.all(deletePromises);
      this.loadWhats(this.criteria);
    },
    shortcutHandler(e) {
      if (isInsert(e)) {
        this.newItem();
        this.dialog = true;
        // important as per v-dialog documentation
        e.stopPropagation();
      }
    },
    defaultItem() {
      this.editedItem = { ...fields };
      this.editedIndex = -1;
      return this.editedItem;
    },
    newItem() {
      this.defaultItem();
      this.editedItem.fiscalYear = this.criteria.fiscalYear;
    },
    setCriteriaByPage(page) {
      const { itemsperpage } = this.getSettings;
      this.criteria.skip = (page - 1) * itemsperpage;
      this.criteria.limit = itemsperpage;
    },
    criteriaChanged() {
      this.loadWhats(this.criteria);
    },
    handlePage(page) {
      this.setCriteriaByPage(page);
      this.loadWhats(this.criteria);
    },
    editItem(item) {
      this.editedIndex = this.whats.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      if (window.confirm('Are you sure you want to delete this item?')) {
        this.$store.dispatch('OD/deleteWhat', item);
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.defaultItem();
        this.dialog = false;
      });
    },
    save() {
      this.$store.dispatch('OD/upsWhat', this.editedItem);
      this.close();
    },
  },
};
</script>

<style lang="sass">
.v-data-table
  > .v-data-footer
    margin-right: 21px
    > .v-data-footer__select
      margin-right: 18px
    > .v-data-footer__icons-before
      margin-right: 9px
    > .v-data-footer__icons-after
      margin-left: 4px
.text-start .clickable:hover
  cursor: pointer
  font-weight: 600
</style>
